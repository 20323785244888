<template>
    <div class="view-root bg-white notes-list-wrapper" style="box-sizing: border-box">
        <div class="flex justify-between items-center" style="padding: 20px 32px;">
            <p class="title">Settings</p>
            <p class="btn-save" @click="saveUserInfo"> Save </p>
        </div>
        <div style="width: 100%;height: 1px;background-color: #E2E8F0;"></div>
        <div style="display: flex;flex-direction: row;flex: 1;">
            <div class="left-nav">
                <p class="title">SERVICES</p>
                <div class="nav-item nav-item-selected">
                    <div style="width: 48px;height: 48px;border-radius: 50%;background-color: white;display: flex;align-items: center;justify-content: center;">
                        <img src="../../assets/icon_user.png" style="width: 24px;object-fit: cover;">
                    </div>
                    <div style="flex: 1;padding-left: 15px;box-sizing: border-box;">
                        <p class="name">Basic Info</p>
                        <p class="intro">Settings waiting to be modified</p>
                    </div>
                </div>
            </div>
            <div style="padding: 20px 32px;flex: 1;box-sizing: border-box;">
                <p class="title" style="font-size: 18px;">Basic Info</p>

                <el-form :model="userInfo" ref="form" :rules="rules">
                    <el-form-item label="User Email" >
                        <el-input v-model="userInfo.user_email" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="Agent Name" prop="user_nickName">
                        <el-input v-model="userInfo.user_nickName" placeholder="Please input your agent name"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>



    </div>
</template>

<script>
import {
    getUserInfo,
    updateUserInfo
} from '../../api/reff'
import { getUserId } from "../../utils/store";
export default {
    data() {
        return {
            userInfo: {
                user_email: '',
                user_nickName: ''
            },
            rules: {
                user_nickName: [
                    { required: true, message: 'Please input your agent name', trigger: 'blur' }
                ],
            },
            loading: false,
        };
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        fetchData() {
            getUserInfo(getUserId()).then((res) => {
                this.showUserInfo(res.data.data)
            })
        },
        saveUserInfo() {
            this.$refs.form.validate((val) => {
                if (val) {
                    let params = {
                        user_id: getUserId(),
                        user_nickName: this.userInfo.user_nickName
                    }
                    updateUserInfo(params).then((res) => {
                        this.$message.success('Save success~')
                        this.showUserInfo(res.data.data)
                    })
                }
            })
        },
        showUserInfo(result) {
            if (result.user_nickName) {
                this.userInfo.user_nickName = result.user_nickName
            }
            this.userInfo.user_email = result.user_email
        }
    },
};
</script>

<style scoped lang="scss">
.left-nav {
    border-right: 1px solid #E2E8F0;
    padding: 20px 0px;
    padding-left: 12px;
    box-sizing: border-box;
    width: 360px;
    height: 100%;

    .title {
        margin-bottom: 20px;
        width: 100%;
        padding-left: 20px;
        box-sizing: border-box;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color: #718096;
    }


    .nav-item {
        width: 100%;
        height: 96px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 3px solid white;
        background-color: white;
        padding-left: 15px;

        .name {
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            color: #1A202C;
        }

        .intro {
            margin-top: 5px;
            font-family: "Poppins";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            color: #718096;
        }

    }

    .nav-item-selected {
        background-color: #FAFAFA;
        border-left: 3px solid #436CFF;
    }

}

.title {
    font-family: "Poppins";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    color: #1A202C;
}

.btn-save {
    cursor: pointer;
    background-color: #FAFAFA;
    width: 128px;
    height: 48px;
    border-radius: 24px;
    text-align: center;
    line-height: 48px;
    color: #004852;
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 500;
}


.notes-list-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.custom-btn {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
    color: white;
    font-family: Poppins;
    background: #ec652b;
    height: 48px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 600;

    &.export-btn {
        color: #005a64;
        border: 2px solid #005a64;
        height: 48px;
        background: white;

        .btn-icon {
            width: 20px;
            height: 20px;
        }
    }

    .btn-icon {
        width: 16px;
        height: 16px;
    }
}

.note-container {
    display: flex;
    gap: 24px;

    .content-container {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        gap: 16px;

        // height: calc(100vh - 106px);
        .note-item {
            width: 100%;
        }
    }
}

@media (max-width: 1200px) {
    .note-container {
        flex-direction: column-reverse;

        .search-container {
            width: 100%;
        }
    }
}
</style>